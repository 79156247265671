import "./footer.scss";

const Footer: React.FC = () => {
    return (
        <div className="contain-wrapper">
            <div className="footer-hero contain">
                <div className="downloads">
                    <h5>Download</h5>
                    <div className="dl-btns">
                        <a
                            className="dl-btn"
                            href="../dl/brochure.pdf"
                            target="_blank"
                        >
                            <h6>BROCHURE</h6>
                            <img src="../images/dl.svg" alt="dl" />
                        </a>

                        <a
                            className="dl-btn"
                            href="../dl/spec.pdf"
                            target="_blank"
                        >
                            <h6>SPECIFICATION</h6>
                            <img src="../images/dl.svg" alt="dl" />
                        </a>

                        <a
                            className="dl-btn"
                            href="../dl/floorplans.pdf"
                            target="_blank"
                        >
                            <h6>FLOORPLANS</h6>
                            <img src="../images/dl.svg" alt="dl" />
                        </a>

                        {/* <div className="dl-btn">
                            <h6>ESG</h6>
                            <img src="../images/dl.svg" alt="dl" />
                        </div> */}
                    </div>
                </div>
                <div className="content-wrapper contact-details">
                    <div className="content-container">
                        <div className="img-wrapper">
                            <img src="../images/101_logo.svg" alt="logo" />
                        </div>
                        <h6>
                            101 Bayham Street <br /> camden, london <br /> NW1
                            0BA
                        </h6>
                        <p className="reg toppad">Available in Q3 2025</p>
                        {/* <div className="logo-wrapper">
              <div className="img-wrapper">
                <img src="../images/instagram.svg" alt="ig" />
              </div>
              <div className="img-wrapper">
                <img src="../images/linkedin.svg" alt="ig" />
              </div>
            </div> */}
                    </div>
                    <div className="content-container">
                        <div className="img-wrapper">
                            <a
                                href="https://www.edwardcharles.co.uk/"
                                target="new"
                            >
                                <img src="../images/ecl.svg" alt="logo" />
                            </a>
                        </div>
                        <div className="agent">
                            <h6>ANDREW OKIN</h6>
                            <p className="reg">
                                <a href="mailto:aokin@edwardcharles.co.uk">
                                    aokin@edwardcharles.co.uk
                                </a>
                            </p>
                            <p className="reg">
                                <a href="tel:+447887714491">
                                    +44 (0)7887 714 491
                                </a>
                            </p>
                        </div>
                        <div className="agent">
                            <h6>IAN BRADSHAW</h6>
                            <p className="reg">
                                <a href="mailto:ibradshaw@edwardcharles.co.uk">
                                    ibradshaw@edwardcharles.co.uk
                                </a>
                            </p>
                            <p className="reg">
                                <a href="tel:+447468525486">
                                    +44 (0)7468 525 486
                                </a>
                            </p>
                        </div>
                        <div className="agent">
                            <h6>Ollie Mitchell</h6>
                            <p className="reg">
                                <a href="mailto:omitchell@edwardcharles.co.uk">
                                    omitchell@edwardcharles.co.uk
                                </a>
                            </p>
                            <p className="reg">
                                <a href="tel:+447769310206">
                                    +44 (0)7769 310 206
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="content-container">
                        <div className="img-wrapper">
                            <a href="https://www.jll.co.uk/" target="new">
                                <img src="../images/jll.svg" alt="logo" />
                            </a>
                        </div>
                        {/* <div className="agent">
                            <h6>George Reynolds</h6>
                            <p className="reg">
                                <a href="mailto:george.reynolds@jll.com">
                                    george.reynolds@jll.com
                                </a>
                            </p>
                            <p className="reg">
                                <a href="tel:+447592112110">
                                    +44 (0)7592 112 110
                                </a>
                            </p>
                        </div> */}
                        <div className="agent">
                            <h6>Michael Oliver</h6>
                            <p className="reg">
                                <a href="mailto:michael.oliver@jll.com">
                                    michael.oliver@jll.com
                                </a>
                            </p>
                            <p className="reg">
                                <a href="tel:+447591200793">
                                    +44 (0)7591 200 793
                                </a>
                            </p>
                        </div>
                        <div className="agent">
                            <h6>Isabella Woodhead</h6>
                            <p className="reg">
                                <a href="mailto:isabella.woodhead@jll.com">
                                    isabella.woodhead@jll.com
                                </a>
                            </p>
                            <p className="reg">
                                <a href="tel:+447709507329">
                                    +44 (0)7709 507 329
                                </a>
                            </p>
                        </div>
                        <div className="agent">
                            <h6>Sarah Brisbane</h6>
                            <p className="reg">
                                <a href="mailto:sarah.brisbane@jll.com">
                                    sarah.brisbane@jll.com
                                </a>
                            </p>
                            <p className="reg">
                                <a href="tel:+447769201458">
                                    +44 (0)7769 201 458
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="content-container">
                        <div className="img-wrapper block" />
                        {/* <div className="agent">
              <h6>Sarah Brisbane</h6>
              <p className="reg">
                <a href="mailto:sarah.brisbane@jll.com">
                  sarah.brisbane@jll.com
                </a>
              </p>
              <p className="reg">
                <a href="tel:+447769201458">+44 (0)7769 201 458</a>
              </p>
            </div> */}
                    </div>
                </div>
                <div className="legal-footer">
                    <p className="legal">©2024 All rights reserved</p>{" "}
                    {/* <p className="legal"> Privacy Policy</p>{" "}
          <p className="legal"> Terms & Conditions</p>{" "}
          <p className="legal"> Disclaimer</p> */}
                </div>
            </div>
            <div className="siren">
                <p className="legal">
                    <a href="https://sirendesign.co.uk/">
                        Designed & Developed By Siren
                    </a>
                </p>
            </div>
        </div>
    );
};

export default Footer;
